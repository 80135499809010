<template>
  <div class="present-container">
    <el-table :data="presentList" style="width: 100%" border>
      <el-table-column
        prop="freeProductId"
        label="赠品名称"
        header-align="center"
        align="center"
      >
        <template v-slot="scope">
          <el-select
            v-model="scope.row.freeProductId"
            placeholder="赠品名称"
            filterable
            @change="presentChangeHandle(scope)"
          >
            <el-option
              v-for="(present, pIndex) in presentOptions"
              :key="pIndex"
              :label="present.title"
              :value="present.id"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="freeProductSpec"
        label="赠品规格"
        header-align="center"
        align="center"
      >
        <template v-slot="scope">
          <el-select
            v-model="scope.row.freeProductSpec"
            placeholder="赠品规格"
            filterable
          >
            <el-option
              v-for="(present, pIndex) in getSpecificationOptions(
                scope.row.freeProductId
              )"
              :key="pIndex"
              :label="present.label"
              :value="present.value"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="freeProductSpec"
        label="赠品数量"
        header-align="center"
        align="center"
      >
        <template v-slot="scope">
          <el-input-number
            v-model="scope.row.freeProductNumber"
            :min="1"
            :max="999"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="操作"
        width="60"
      >
        <template v-slot="scope">
          <el-button
            v-if="scope.$index === 0"
            type="text"
            class="oper-btn"
            @click="addPresentHandle()"
            >追加</el-button
          >
          <el-button
            class="oper-btn del-btn"
            v-else
            type="text"
            @click="removePresentHandle(scope)"
            >移除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  props: {
    presentOptions: {
      type: Array,
      default: () => [],
    },
    promotionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      presentList: [
        { freeProductId: "", freeProductSpec: "", freeProductNumber: 1 },
      ],
    };
  },
  watch: {
    promotionList: {
      handler(val) {
        if (val) {
          this.presentList = cloneDeep(val).map((item) => {
            return {
              ...item,
              freeProductId: item.freeProductId
                ? Number(item.freeProductId)
                : "",
              freeProductNumber: Number(item.freeProductNumber),
            };
          });
          if (!this.presentList.length) {
            this.presentList = [
              { freeProductId: "", freeProductSpec: "", freeProductNumber: 1 },
            ];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getSpecificationOptions(productId) {
      const present = this.presentOptions.find((item) => item.id === productId);
      if (present && present.specList) {
        console.log("xx===========",present)
        const specList = cloneDeep(present.specList);
        return this.getSpecCartesian(specList);
      }

      return [];
    },

    presentChangeHandle({ row, $index }) {
      this.presentList[$index].freeProductSpec = "";
    },

    removePresentHandle({ row, $index }) {
      this.presentList.splice($index, 1);
    },

    addPresentHandle() {
      this.presentList.push({ freeProductNumber: 1 });
    },

    getSpecCartesian(specList) {
      if (!specList.length) {
        return [];
      }

      const alls = specList.map((spec) => {
        return spec.child.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
      });

      if (specList.length === 1) {
        return alls[0];
      }

      let specCartesian = [];
      const cartesian = (arr1, arr2, index) => {
        if (index < alls.length) {
          const res = [];
          for (let i = 0; i < arr1.length; i++) {
            for (let j = 0; j < arr2.length; j++) {
              res.push({
                label: arr1[i].label + " - " + arr2[j].label,
                value: arr1[i].value + "," + arr2[j].value,
              });
            }
          }
          specCartesian = res;
          cartesian(res, alls[index + 1], index + 1);
        }
      };
      cartesian(alls[0], alls[1], 1);
      return specCartesian;
    },
  },
};
</script>

<style lang="scss" scoped>
.present-container {
  width: 100%;

  .del-btn {
    color: #f56c6c;
  }
  .oper-btn:hover {
    text-decoration: underline;
  }
}
</style>
