<template>
  <div>
    <el-dialog
      title="规格互斥"
      :model-value="modelValue"
      width="800px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="alternative-container">
        <el-button type="primary" @click="addRule">添加互斥规则</el-button>
        <div>
          <div class="rules-header">
            <div>属性名称</div>
            <div>属性值</div>
            <div>互斥信息</div>
          </div>
          <div class="rules-ctan">
            <template v-if="ruleList.length">
              <div
                class="rule-item"
                v-for="(ruleItem, index) in ruleList"
                :key="index"
              >
                <div class="spec-name">
                  <el-select v-model="ruleItem.specName" placeholder="属性名称">
                    <el-option
                      v-for="item in propOptioins"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="spec-value">
                  <el-select
                    v-model="ruleItem.specChildName"
                    placeholder="属性值"
                  >
                    <el-option
                      v-for="item in getValueOptions(ruleItem.specName)"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="del-icon_ctan">
                  <el-button
                    class="icon-btn fa fa-times"
                    type="danger"
                    @click="removeRule(index)"
                  ></el-button>
                </div>
                <div class="alternative-info">
                  <div
                    v-for="(excludeItem, eIndex) in ruleItem.excludeSpecList"
                    :key="eIndex"
                    class="alternative-item"
                  >
                    <el-select
                      v-model="excludeItem.name"
                      placeholder="互斥属性名称"
                    >
                      <el-option
                        v-for="item in getAlterPropOptions(ruleItem.specName)"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <span>-</span>
                    <el-select
                      v-model="excludeItem.child"
                      placeholder="互斥属性值"
                      multiple
                      collapse-tags
                    >
                      <el-option
                        v-for="item in getValueOptions(excludeItem.name)"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="del-icon_ctan">
                      <el-button
                        class="icon-btn fa fa-minus"
                        type="danger"
                        @click="
                          removeAlternative(
                            ruleItem.excludeSpecList,
                            eIndex,
                            index
                          )
                        "
                      ></el-button>
                    </div>
                  </div>
                  <div class="alternative-item">
                    <el-button
                      type="primary"
                      @click="addAlternative(ruleItem.excludeSpecList)"
                      >添加级联互斥</el-button
                    >
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="empty-rule">暂无互斥规则</div>
          </div>
        </div>
      </div>
      <template #footer>
        <div>
          <el-button @click="cancelHandle">取 消</el-button>
          <el-button type="primary" @click="submitHandle">保 存</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  addAlternativeRule,
  editAlternativeRule,
  getAlternativeByProduct,
  deleteAlternativeRule,
} from "@/apis/unidrink/product";
import { msgErr, msgSuc } from "@/helpers/message";
import { remove, cloneDeep } from "lodash";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    alterData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleList: [
        {
          specName: "",
          specChildName: "",
          excludeSpecList: [{ name: "", child: [] }],
        },
      ],
      specList: [],
      initRuleIds: [],
    };
  },
  computed: {
    propOptioins() {
      return this.specList.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },
  },
  emits: ["update:modelValue", "refresh"],
  watch: {
    modelValue(val) {
      if (val) {
        //
        console.log(this.alterData);
        this.specList = this.alterData
          ? cloneDeep(this.alterData.specList)
          : [];
        console.log(this.specList);
        this.getAliternativeData();
      }
    },
  },
  methods: {
    addRule() {
      this.ruleList.push({
        specName: "",
        specChildName: "",
        excludeSpecList: [{ name: "", child: [] }],
      });
    },

    removeRule(index) {
      this.ruleList.splice(index, 1);
    },

    addAlternative(excludeSpecList) {
      excludeSpecList.push({ name: "", child: [] });
    },
    removeAlternative(excludeSpecList, eIndex, rIndex) {
      excludeSpecList.splice(eIndex, 1);
      if (!excludeSpecList.length) {
        this.ruleList.splice(rIndex, 1);
      }
    },

    getValueOptions(prop) {
      const found = this.specList.find((item) => item.name === prop);
      return (found ? found.child : []).map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    },

    getAlterPropOptions(prop) {
      let index = 0;
      this.specList.some((spec, i) => {
        if (spec.name === prop) {
          index = i;
          return true;
        }
      });

      const remainProps = this.specList.slice(index + 1);
      return remainProps.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },

    getAliternativeData() {
      getAlternativeByProduct(this.alterData.id).then(
        ({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
          }

          this.ruleList = result.list.map((item) => {
            return {
              ...item,
              excludeSpecList: JSON.parse(item.excludeSpecList),
            };
          });
          this.initRuleIds = result.list.map((item) => item.id);
        }
      );
    },

    packData(rule) {
      return {
        ...rule,
        productId: this.alterData.id,
        specName: rule.specName,
        specChildName: rule.specChildName,
        excludeSpecList: JSON.stringify(rule.excludeSpecList),
      };
    },

    close() {
      this.$emit("update:modelValue", false);
    },
    cancelHandle() {
      this.close();
    },

    submitHandle() {
      const promiseList = this.ruleList.map((rule) => {
        return new Promise((resolve, reject) => {
          const reqData = this.packData(rule);
          const promise = reqData.id ? editAlternativeRule : addAlternativeRule;
          promise(reqData)
            .then(({ code, result, message }) => {
              if (code !== "0000") {
                reject(message);
              } else {
                resolve(result);
              }
            })
            .catch(reject);
        });
      });

      // 需要删除的id
      const delIds = [];
      this.initRuleIds.forEach((ruleId) => {
        if (!this.ruleList.find((rule) => rule.id === ruleId)) {
          // rule id需要删除
          delIds.push(ruleId);
        }
      });
      if (delIds.length) {
        const delPromise = new Promise((resolve, reject) => {
          deleteAlternativeRule(delIds)
            .then(({ code, result, message }) => {
              if (code !== "0000") {
                reject(message);
              } else {
                resolve(result);
              }
            })
            .catch(reject);
        });
        promiseList.push(delPromise);
      }

      // 事务通讯
      Promise.all(promiseList)
        .then((res) => {
          this.close();
          this.$emit("refresh");
          msgSuc("操作成功！");
        })
        .catch(() => {
          this.getAliternativeData();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.alternative-container {
  padding-left: 40px;

  .rules-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    background-color: #eeeeee;
    color: #000;
    border-radius: 2px;
    margin: 10px 0;
    padding-left: 5px;

    div {
      width: 165px;

      &:nth-child(2) {
        width: 200px;
      }
    }
  }

  .rules-ctan {
    .rule-item {
      display: flex;
      flex-direction: row;
      padding-left: 5px;
      position: relative;
      padding-bottom: 10px;

      & + .rule-item {
        border-top: 1px solid #efefef;
        padding-top: 10px;
      }

      &::before {
        content: "·";
        font-size: 24px;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: -10px;
      }

      .spec-name,
      .spec-value {
        height: 32px;
        min-width: 160px;
        width: 160px;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
      }
      .spec-name {
        padding-right: 5px;
        border-right: 1px solid #eee;
      }
      .spec-value {
        padding-left: 5px;
      }

      .del-icon_ctan {
        height: 32px;
        display: flex;
        align-items: center;
        margin: 0 5px;
      }

      .alternative-info {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #eee;
        flex-shrink: 1;
        flex-grow: 1;
        .alternative-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 10px;
          & + .alternative-item {
            margin-top: 10px;
          }
        }
      }
    }

    .empty-rule {
      border: 1px solid #efefef;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
    }
  }
}
</style>
