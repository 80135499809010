<template>
  <div>
    <el-dialog
      title="满促"
      :model-value="modelValue"
      width="800px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="promotion-container">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="起赠购买量：" prop="least">
            <el-input v-model="form.least" type="number"></el-input>
          </el-form-item>
          <el-form-item label="赠送产品：">
            <PromotionTable
              ref="present"
              :presentOptions="presentProductOptions"
              :promotionList="promotionList"
            ></PromotionTable>
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio label="01">全部禁用</el-radio>
              <el-radio label="02">全部启用</el-radio>
              <el-radio label="03">单点启用一起喝禁用</el-radio>
              <el-radio label="04">单点禁用一起喝启用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="cancelHandle">取 消</el-button>
          <el-button type="primary" @click="submitHandle">保 存</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  addPromotion,
  editPromotion,
  getPromotionByProduct,
  deletePromotion,
  getSubProductOptions,
} from "@/apis/unidrink/product";
import { msgErr, msgSuc } from "@/helpers/message";
import PromotionTable from "./promotionTable.vue";
import { cloneDeep } from "lodash";

export default {
  components: { PromotionTable },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    promotionData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      initForm: {
        least: "",
        status: "01",
      },
      form: {},
      rules: {
        least: [
          { required: true, message: "起增购买量不能为空", trigger: "blur" },
        ],
      },
      presentProductOptions: [],
      presentSpecOptions: [],
      promotionList: [],
    };
  },
  computed: {
    propOptioins() {
      return this.specList.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },
  },
  emits: ["update:modelValue", "refresh"],
  watch: {
    modelValue(val) {
      if (val) {
        this.form = cloneDeep(this.initForm);
        this.getProductOptions(this.promotionData.shopId);
        this.getPromotionData();
      }
    },
  },
  methods: {
    async getProductOptions(shopId) {
      await getSubProductOptions(this.presentProductOptions, {
        shopId,
        type: "01",
      });

      // 赠品不能赠送自己
      // this.presentProductOptions = this.presentProductOptions.filter((item) => {
      //   return item.id !== Number(this.promotionData.id);
      // });
    },

    removeRule(index) {
      this.ruleList.splice(index, 1);
    },

    addAlternative(excludeSpecList) {
      excludeSpecList.push({ name: "", child: [] });
    },
    removeAlternative(excludeSpecList, eIndex, rIndex) {
      excludeSpecList.splice(eIndex, 1);
      if (!excludeSpecList.length) {
        this.ruleList.splice(rIndex, 1);
      }
    },

    getValueOptions(prop) {
      const found = this.specList.find((item) => item.name === prop);
      return (found ? found.child : []).map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    },

    getAlterPropOptions(prop) {
      let index = 0;
      this.specList.some((spec, i) => {
        if (spec.name === prop) {
          index = i;
          return true;
        }
      });

      const remainProps = this.specList.slice(index + 1);
      return remainProps.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },

    getPromotionData() {
      getPromotionByProduct(this.promotionData.id).then(
        ({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
          }

          if (result.list.length) {
            this.form.least = result.list[0].least;
            this.form.status = result.list[0].status;
          }

          this.promotionList = result.list.map((item) => {
            return {
              ...item,
            };
          });
          this.initPresentIds = result.list.map((item) => item.id);
        }
      );
    },

    packData(present) {
      const cond = {
        ...present,
        least: this.form.least,
        productId: this.promotionData.id,
        shopId: this.promotionData.shopId,
        status: this.form.status,
      };
      return cond;
    },

    close() {
      this.$emit("update:modelValue", false);
    },

    cancelHandle() {
      this.close();
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const presentList = this.$refs.present.presentList;

        const promiseList = presentList.map((present) => {
          return new Promise((resolve, reject) => {
            const reqData = this.packData(present);
            const promise = reqData.id ? editPromotion : addPromotion;
            promise(reqData)
              .then(({ code, result, message }) => {
                if (code !== "0000") {
                  reject(message);
                } else {
                  resolve(result);
                }
              })
              .catch(reject);
          });
        });

        // 需要删除的id
        const delIds = [];
        this.initPresentIds.forEach((ruleId) => {
          if (!presentList.find((rule) => rule.id === ruleId)) {
            // rule id需要删除
            delIds.push(ruleId);
          }
        });
        if (delIds.length) {
          const delPromise = new Promise((resolve, reject) => {
            deletePromotion(delIds)
              .then(({ code, result, message }) => {
                if (code !== "0000") {
                  reject(message);
                } else {
                  resolve(result);
                }
              })
              .catch(reject);
          });
          promiseList.push(delPromise);
        }

        // 事务通讯
        Promise.all(promiseList)
          .then((res) => {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          })
          .catch(() => {
            this.getAliternativeData();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.promotion-container {
}
</style>
